import "../css/app.css";

// Navigation toggle
window.addEventListener("load", function () {
  let main_navigation = document.querySelector("#primary-menu");
  document
    .querySelector("#primary-menu-toggle")
    .addEventListener("click", function (e) {
      e.preventDefault();
      main_navigation.classList.toggle("hidden");
    });
});

var swiper = new Swiper(".evDriverSwiper", {
  navigation: {
    nextEl: ".ev-driver-swiper-button-next",
    prevEl: ".ev-driver-swiper-button-prev",
  },
});

var swiper = new Swiper(".magazineSwiper", {
  slidesPerView: 1.2,
  spaceBetween: 24,
  breakpoints: {
    768: {
      slidesPerView: 3,
    },
  },
});

var swiper = new Swiper(".partnersSwiper", {
  slidesPerView: 1.2,
  spaceBetween: 40,
  breakpoints: {
    768: {
      slidesPerView: 3,
    },
  },
  navigation: {
    nextEl: ".partnersSwiper .swiper-button-next",
    prevEl: ".partnersSwiper .swiper-button-prev",
  },
});
